<script setup>
import {useCurrencyStore} from "~/store/currencyStore.js";
import Plugin from "~/components/organisms/Plugin.vue";
import PluginsPage from "~/components/templates/PluginsPage.vue";
import {fetchWithErrorHandling} from "~/composables/fetchWithErrorHandling.js";
import {useRoute} from "#vue-router";

const currencyStore = useCurrencyStore()
const {t} = useI18n();

const props = defineProps({
  bottomDescription: {
    type: Object,
  },
  city: {
    type: String,
  },
  breadcrumbs: {
    type: Array,
  }
});

const route = useRoute();

const data = await fetchWithErrorHandling(`/page/`, {
  query: {...route.query},
});

useHead({
  script: [
    {
      type: 'application/ld+json',
      children: {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": t('meta.domain'),
        "potentialAction": {
          "@type": "SearchAction",
          "target": t('meta.search'),
          "query-input": "required name=search_term_string"
        }
      }
    },
    {
      type: 'application/ld+json',
      children: {
        "@context": "http://www.schema.org",
        "@type": "LocalBusiness",
        "name": "Hillceramic",
        "url": t('meta.domain'),
        "logo": t('meta.logo'),
        "image": t('meta.logo'),
        "description": t('meta.localBusiness.description'),
        "email": "order@hillceramic.se ",
        "hasMap": "https://goo.gl/maps/32STmkyLsmehfKDD7",
        "openingHours": ["Mo-Th 08:00-16:00", "Fr 08:00-14:00"],
        "telephone": "0340549797",
        "priceRange": currencyStore.currency,
        "sameAs": ["https://www.instagram.com/hillceramic/", "https://www.youtube.com/channel/UCrRjvxI9oLEXNDn4FK9osJQ", "https://www.facebook.com/hillceramic/", "https://www.pinterest.se/hillceramic/"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Fastarpsv\u00e4gen 14",
          "addressLocality": "Tv\u00e5\u00e5ker",
          "postalCode": "432 78",
          "addressCountry": "Sweden"
        },
        "geo": {"@type": "GeoCoordinates", "longitude": "57.043524", "latitude": "12.388961"}
      }

    },
  ],
  title: data.value.data.meta_title,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: data.value.data.meta_description
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: data.value.data.meta_description
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: data.value.data.meta_title
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: t('meta.domain')
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: t('meta.logo')
    },
  ]
})


const grouped_plugins = data.value.data.grouped_plugins

</script>

<template>
  <div class="big-page-wrapper">
    <PluginsPage :shifted-background="true">
      <template v-slot:[position] v-for="[position, plugins] of Object.entries(grouped_plugins)">
        <template v-for="plugin in plugins">
          <Plugin :data="plugin.data" v-if="plugin.data !== false" :name="plugin.name" :settings="plugin.settings"/>
        </template>
      </template>
    </PluginsPage>

    <div class="container" v-if="bottomDescription?.html">
      <div v-html="bottomDescription.html"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.grey-bg {
  background-position-y: 450px;

  @include smd {
    background-color: white;
  }
}
</style>
